// Next imports
import { Image } from "@chakra-ui/react";

interface TypeImageCover {
  src: string | undefined;
  alt: string;
  flow: object | undefined;
}

const defaultProps = {
  src: undefined,
  alt: "Insert text",
  flow: undefined,
};

export const ImageCover = (props: TypeImageCover) => {
  const { src, alt, flow } = props;

  return (
    <>
      <Image
        src={src}
        alt={alt}
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        objectFit="cover"
        {...flow}
      />
    </>
  );
};

ImageCover.defaultProps = defaultProps;
