// View imports
import { About } from "./views";
// Elements imports
import { ContainerPage } from "src/elements";
//Ga imports
import ReactGA from "react-ga4";

ReactGA.initialize("G-K2FNXGDC7L");

export const App = () => (
  <>
    <ContainerPage>
      <About />
    </ContainerPage>
  </>
);
