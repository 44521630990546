import { Heading, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { ContainerEssential, ContainerFlex, ContainerGrid } from "src/elements";
import { FaMoneyCheckAlt, FaAward, FaHandHoldingHeart } from "react-icons/fa";

export const AboutIntroduction = () => {
  const options = [
    {
      title: "High affordability",
      icon: FaMoneyCheckAlt,
      description:
        "We aim for the majority of individuals and businesses to not find money as an obstacle to accessing our products.",
    },
    {
      title: "Excellence and quality",
      icon: FaAward,
      description:
        "We find technical and professional excellence to be our primary value in the development of solutions.",
    },
    {
      title: "Social responsability",
      icon: FaHandHoldingHeart,
      description:
        "We understand the power of technology, which is why we only develop high-value solutions for society.",
    },
  ];

  return (
    <>
      <ContainerEssential bgColor="gray.50">
        <ContainerFlex position="relative" flow={{ px: "5", py: "36" }}>
          <Text
            textAlign="center"
            fontSize="lg"
            width={{ base: "100%", md: "50%" }}
            mb="20"
          >
            As Uncle Ben said, "
            <u>With great power comes great responsibility</u>." At Tarxis, we
            focus on developing products with artificial intelligence (AI) based
            on three fundamental principles.
          </Text>
          <ContainerGrid width="auto" columns={{ base: 1, md: 3 }} gap="10">
            {options.map((item, i) => (
              <>
                <ContainerEssential
                  width={{ base: "100%", md: "96" }}
                  bgColor="gray.100"
                  key={i}
                  flow={{ textAlign: "center", rounded: "xl", padding: "10" }}
                >
                  <Icon
                    mx="auto"
                    mb="3"
                    as={item.icon}
                    boxSize={14}
                    color="teal.500"
                  />
                  <Heading fontSize="lg" fontWeight="medium" mb="3">
                    {item.title}
                  </Heading>
                  <Text textAlign="center" fontWeight="light">
                    {item.description}
                  </Text>
                </ContainerEssential>
              </>
            ))}
          </ContainerGrid>
        </ContainerFlex>
      </ContainerEssential>
    </>
  );
};
