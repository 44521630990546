// Components imports
import { AboutCover, AboutFooter } from "src/components";
import { AboutIntroduction, AboutProducts } from "src/components";

export const About = () => {
  return (
    <>
      <AboutCover />
      <AboutIntroduction />
      <AboutProducts />
      <AboutFooter />
    </>
  );
};
