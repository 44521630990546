// Chakra imports
import { Flex } from "@chakra-ui/react";

interface TypeContainerBar {
  height: string | object;
  width: string | object;
  bgColor: string | undefined;
  justify: string | object;
  children: any | undefined;
  isFooter: boolean;
  flow: object | undefined;
}

const defaultProps = {
  height: "16",
  width: "100%",
  bgColor: undefined,
  justify: "center",
  children: undefined,
  isFooter: false,
  flow: undefined,
};

export const ContainerBar = (props: TypeContainerBar) => {
  const { height, width, bgColor, justify, children, isFooter, flow } = props;

  return (
    <>
      <Flex
        position="absolute"
        top={isFooter ? "unset" : "0"}
        bottom="0"
        left="0"
        height={height}
        width={width}
        bgColor={bgColor}
        justifyContent={justify}
        alignItems="center"
        {...flow}
      >
        {children}
      </Flex>
    </>
  );
};

ContainerBar.defaultProps = defaultProps;
