// Chakra imports
import { SimpleGrid } from "@chakra-ui/react";

interface TypeContainerGrid {
  columns: number | object;
  gap: string | object;
  height: string | object;
  width: string | object;
  children: any | undefined;
  flow: object | undefined;
}

const defaultProps = {
  columns: 2,
  gap: "4",
  height: "auto",
  width: "auto",
  children: undefined,
  flow: undefined,
};

export const ContainerGrid = (props: TypeContainerGrid) => {
  const { columns, gap, height, width, children, flow } = props;

  return (
    <>
      <SimpleGrid
        height={height}
        width={width}
        columns={columns}
        gap={gap}
        {...flow}
      >
        {children}
      </SimpleGrid>
    </>
  );
};

ContainerGrid.defaultProps = defaultProps;
