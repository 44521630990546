// Chakra imports
import { Flex, IconButton } from "@chakra-ui/react";

interface TypeBtnIcon {
  name: string;
  icon: Element | any;
  onClick(event: any): void;
  flow: object | undefined;
  color?: string;
  size?: string;
}

const defaultProps = {
  name: "Insert name",
  icon: undefined,
  onClick: undefined,
  flow: undefined,
  color: "white",
  size: "2xl",
};

export const BtnIcon = (props: TypeBtnIcon) => {
  const { name, icon, onClick, color, flow, size } = props;

  return (
    <>
      <Flex position="relative" justify="center" {...flow}>
        <IconButton
          aria-label={name}
          rounded="lg"
          fontSize={size}
          color={color}
          colorScheme="transparent"
          icon={icon}
          onClick={onClick}
        />
      </Flex>
    </>
  );
};

BtnIcon.defaultProps = defaultProps;
