// React imports
import * as React from "react";
import * as ReactDOM from "react-dom/client";
// Chakra imports
import { ChakraProvider } from "@chakra-ui/react";
// Config imports
import { theme } from "src/config";
// Components imports
import { App } from "src/app";

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);
