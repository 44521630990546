// Elements imports
import { ContainerBar, ContainerFlex } from "src/elements";
import { ContainerGrid, ContainerPage } from "src/elements";
import { Heading, Image, Text } from "@chakra-ui/react";
// Images imports
import bgAbout from "src/assets/img/bgAbout.jpg";
import logo from "src/assets/img/logo.png";
import decoration0 from "src/assets/img/decoration0.png";
// Video imports
import bgAboutMp4 from "src/assets/video/bgAbout.mp4";
import bgAboutWebm from "src/assets/video/bgAbout.webm";
import bgAboutOgg from "src/assets/video/bgAbout.ogg";

export const AboutCover = () => {
  return (
    <>
      <ContainerPage>
        <video autoPlay muted loop playsInline id="myVideo" poster={bgAbout}>
          <source src={bgAboutMp4} type="video/mp4" />
          <source src={bgAboutWebm} type="video/webm" />
          <source src={bgAboutOgg} type="video/ogg" />
          Your browser does not support HTML5 video.
        </video>

        <ContainerFlex
          flow={{
            bgGradient: {
              base: "linear(to-t, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5))",
              md: "linear(to-r, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))",
            },
          }}
        >
          <ContainerBar height="28" flow={{ px: "5", py: "6" }}>
            <Image height="100%" src={logo} alt="Tarxis Tecnology" mr="auto" />
          </ContainerBar>
          <ContainerGrid width="100%" columns={{ base: 1, md: 2 }}>
            <ContainerFlex
              position="relative"
              flow={{ p: "10", textAlign: { base: "center", md: "right" } }}
            >
              <Heading
                fontSize="3xl"
                color="teal.300"
                mb="6"
                width={{ base: "100%", md: "96" }}
              >
                Tarxis Technology
              </Heading>
              <Text
                color="gray.50"
                fontSize="lg"
                mb="10"
                width={{ base: "100%", md: "96" }}
              >
                We build products based on artificial intelligence and big data
                for human productivity.
              </Text>
            </ContainerFlex>
          </ContainerGrid>
          <Image position="absolute" bottom="0" left="0" src={decoration0} />
        </ContainerFlex>
      </ContainerPage>
    </>
  );
};
