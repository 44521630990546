// Chakra imports
import { Box } from "@chakra-ui/react";

interface TypeContainerEssential {
  position: "absolute" | "relative";
  width: string | object;
  height: string | object;
  bgColor: string | undefined;
  children: any | undefined;
  flow: object | undefined;
}

const defaultProps = {
  position: "relative",
  width: "auto",
  height: "auto",
  bgColor: "transparent",
  children: undefined,
  flow: undefined,
};

export const ContainerEssential = (props: TypeContainerEssential) => {
  const { position, width, height, bgColor, children, flow } = props;

  return (
    <>
      <Box
        position={position}
        height={height}
        width={width}
        bgColor={bgColor}
        {...flow}
      >
        {children}
      </Box>
    </>
  );
};

ContainerEssential.defaultProps = defaultProps;
