import { Text } from "@chakra-ui/react";
import { ContainerEssential, ContainerFlex } from "src/elements";

export const AboutProducts = () => {
  return (
    <>
      <ContainerEssential bgColor="teal.500">
        <ContainerFlex position="relative" flow={{ px: "5", py: "10" }}>
          <Text
            color="white"
            textAlign="center"
            fontSize="lg"
            width={{ base: "100%", md: "50%" }}
            fontWeight="light"
          >
            Soon you will be able to discover our first product.
          </Text>
        </ContainerFlex>
      </ContainerEssential>
    </>
  );
};
