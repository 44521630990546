// Elements imports
import { Text } from "@chakra-ui/react";
import { BtnIcon, ContainerEssential, ContainerFlex } from "src/elements";
// Icons imports
import { FaLinkedin, FaNodeJs, FaAws } from "react-icons/fa";
import { SiMicrosoftazure, SiPytorch, SiTensorflow } from "react-icons/si";

export const AboutFooter = () => {
  const options = [
    { icon: <SiMicrosoftazure />, url: "https://azure.microsoft.com/" },
    { icon: <FaNodeJs />, url: "https://nodejs.org/" },
    { icon: <SiPytorch />, url: "https://pytorch.org/" },
    { icon: <FaAws />, url: "https://aws.amazon.com/" },
    { icon: <SiTensorflow />, url: "https://www.tensorflow.org/" },
  ];
  return (
    <>
      <ContainerEssential
        bgColor="gray.900"
        flow={{ px: "5", pt: "10", pb: "2" }}
      >
        <ContainerEssential flow={{ mb: "10" }}>
          <Text color="gray.500" textAlign="left" fontWeight="light" mb="2">
            Experts in the use of:
          </Text>
          <ContainerFlex direction="row" justify="start" position="relative">
            {options.map((item, i) => (
              <>
                <BtnIcon
                  key={i}
                  size="2xl"
                  color="gray.500"
                  onClick={() => {
                    window.open(item.url, "_blank");
                  }}
                  icon={item.icon}
                  flow={{ mr: "6" }}
                />
              </>
            ))}
          </ContainerFlex>
        </ContainerEssential>
        <ContainerFlex direction="row" position="relative" height="auto">
          <Text color="gray.400" fontWeight="light" mr="auto">
            © 2023 Tarxis Technology
          </Text>
          <BtnIcon
            onClick={() => {
              window.open(
                "https://www.linkedin.com/company/tarxis-technology",
                "_blank"
              );
            }}
            icon={<FaLinkedin />}
          />
        </ContainerFlex>
      </ContainerEssential>
    </>
  );
};
