// Chakra imports
import { Flex } from "@chakra-ui/react";

interface TypeContainerFlex {
  position: "absolute" | "relative";
  width: string | object;
  height: string | object;
  direction: "column" | "row" | object;
  align: string | object;
  justify: string | object;
  bgColor: string | undefined;
  children: any | undefined;
  flow: object | undefined;
}

const defaultProps = {
  position: "absolute",
  width: "100%",
  height: "100%",
  direction: "column",
  align: "center",
  justify: "center",
  bgColor: "transparent",
  children: undefined,
  flow: undefined,
};

export const ContainerFlex = (props: TypeContainerFlex) => {
  const { position, width, height, direction, align } = props;
  const { justify, bgColor, children, flow } = props;

  return (
    <>
      <Flex
        position={position}
        top="0"
        left="0"
        height={height}
        width={width}
        flexDirection={direction}
        alignItems={align}
        justifyContent={justify}
        bgColor={bgColor}
        {...flow}
      >
        {children}
      </Flex>
    </>
  );
};

ContainerFlex.defaultProps = defaultProps;
