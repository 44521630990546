// Chakra imports
import { Box } from "@chakra-ui/react";

interface TypeContainerPage {
  bgColor: string | undefined;
  children: any | undefined;
  flow: object | undefined;
}

const defaultProps = {
  bgColor: "transparent",
  children: undefined,
  flow: undefined,
};

export const ContainerPage = (props: TypeContainerPage) => {
  const { bgColor, children, flow } = props;

  return (
    <>
      <Box
        position="relative"
        height="100%"
        width="100%"
        bgColor={bgColor}
        {...flow}
      >
        {children}
      </Box>
    </>
  );
};

ContainerPage.defaultProps = defaultProps;
