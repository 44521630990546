// chakra imports
import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  styles: {
    global: {
      "html, body, #root": {
        height: "100%",
      },
      form: {
        width: "100%",
      },
      video: {
        position: "absolute",
        minHeight: "100%",
        minWidth: "100%",
        maxHeight: "100%",
        maxWidth: "100%",
        objectFit: "cover",
      },
    },
  },
  fonts: {
    body: `'Heebo', sans-serif`,
    heading: `'Heebo', sans-serif`,
    mono: `'Heebo', sans-serif`,
  },
});
